<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技参加第五届全球金融科技创业大赛上海赛区总决赛
              </p>
              <div class="fst-italic mb-2">2023年3月23日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >创业大赛</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/30/01-大赛.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    3月23日，第五届全球金融科技创业大赛上海赛区总决赛圆满落幕。非凸科技经过层层选拔，凭借其创新性和优异性，在参赛的众多项目里脱颖而出并参与此次上海赛区的线下路演，争夺晋级全国总决赛的名额。
                  </p>
                  <p>
                    本次大赛由清华大学金融科技研究院作为学术指导单位发起，北京金融科技研究院作为指导单位，北京清芬新金融研究院主办，旨在寻找金融科技领域最具潜力的创业项目和最具创新性的商业模式。
                  </p>
                  <p>
                    在公开路演环节，非凸科技联合创始人&CEO王浚澎从商业模式、发展规划及核心团队等方面展示了自己的创业项目。在闭门路演环节，王浚澎又从商业模式创新、商业成长性、团队实力及资源优势等多维度详细地介绍了创业项目，并与评审嘉宾进行了互动交流。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/30/02-大赛.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    整个路演过程，激烈交锋，掌声不断。在相互切磋中，参赛者之间、参赛者与评委之间，不断碰撞出新的火花，掀起一阵又一阵热议金融科技创新的话题。
                  </p>
                  <p>
                    此次参赛，极大地促进了非凸科技与行业间的交流和学习，加强了企业间的互动联络，也让非凸科技清晰地认识到自身发展定位，开阔发展思路。
                  </p>
                  <p>
                    当前，非凸科技已在算法成熟度、技术领先性以及应用落地推进等方面取得较大进展，并得到券商及管理人的高度认可。未来，非凸科技将不畏挑战，坚持技术创新，在变化中求新增效，为金融科技行业高质量发展赋能！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News30",
};
</script>

<style></style>
